(function ($) {
  Drupal.behaviors.contentBlockOverlay = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $body = $('body');
      var $modules = $('.js-content-block-overlay', context);
      var $overlayCta = $('.js-content-block-overlay-cta', context);
      var $closeOverlayCta = $('.js-content-block-overlay-close-btn', context);

      if ($modules.length === 0) {
        return;
      }

      $modules.each(function (i, item) {
        var $overlayItem = $(item);
        var $overlayItemCta = $overlayItem.parent().find('.js-content-block-overlay-cta');
        var $overlayParent = $overlayItem.parent('.js-content-block-overlay-cta');
        var $ctaLink = $overlayItemCta.length > 0 ? $overlayItemCta : $overlayParent;

        $overlayItem.addClass('overlay' + i).appendTo('body');
        $ctaLink.attr('overlay-class', 'overlay' + i);
      });

      $overlayCta.once().on('click', function (e) {
        e.preventDefault();

        var cta = $(this);
        var overlayClass = cta.attr('overlay-class');
        var $overlayContainer = $('.' + overlayClass, context);

        if ($overlayContainer.length > 0) {
          var $galleryFormatter = $overlayContainer.find('.js-gallery-formatter');
          var $galleryItem = $galleryFormatter.find('.js-gallery-item');
          var $galleryItemCount = $galleryFormatter.find('.js-gallery-item-content-count');

          // Set up first gallery item.
          if (Drupal.behaviors.galleryFormatterV1 !== undefined &&
            Drupal.behaviors.galleryFormatterV1.firstItem !== undefined) {
            Drupal.behaviors.galleryFormatterV1.firstItem($galleryItem, $galleryItemCount);
          }

          setTimeout(function () {
            $body.addClass('content-block-overaly-open');
            $overlayContainer.addClass('active');
            if ($galleryFormatter.hasClass('js-overlay-full-display')) {
              $overlayContainer.addClass('full-display');
            }
            if ($galleryFormatter.find('.js-text-above-media').length > 0) {
              $overlayContainer.addClass('full-display__text-media');
            }
          }, 50);
        }
      });

      $closeOverlayCta.once().on('click', function () {
        $body.removeClass('content-block-overaly-open');
        $('.js-content-block-overlay').removeClass('active');
      });
    }
  };
})(jQuery, Drupal, window.site || {});
